const dayOfWeeksData = [
  { key: '0', label: 'Sunday', abbr: 'Sun' },
  { key: '1', label: 'Monday', abbr: 'Mon' },
  { key: '2', label: 'Tuesday', abbr: 'Tue' },
  { key: '3', label: 'Wednesday', abbr: 'Wed' },
  { key: '4', label: 'Thursday', abbr: 'Thu' },
  { key: '5', label: 'Friday', abbr: 'Fri' },
  { key: '6', label: 'Saturday', abbr: 'Sat' }
]

export default dayOfWeeksData
