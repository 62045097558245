<template>
  <form
    v-if="!isDisabled"
    @submit.prevent="scheduleMeasure"
  >
    <div class="form-group measure-selector">
      <label v-if="!showMeasuresAssigned">{{ measureListTitle }} *</label>
      <multiselect
        v-if="availableMeasures && !customMeasure"
        v-model="selectedMeasure"
        open-direction="bottom"
        :options="availableMeasuresList"
        :show-labels="false"
        :hide-selected="true"
        :placeholder="$t('selectMeasure')"
        :internal-search="false"
        :preserve-search="currentBrowser.isExplorer"
        label="text"
        track-by="id"
        @select="assignMeasureToSchedule"
        @search-change="searchMeasures"
      >
        <template
          v-if="selectedMeasure && !selectedMeasure.id"
          slot="singleLabel"
        >
          <span class="as-placeholder">{{ $t('selectMeasure') }}</span>
        </template>
        <template slot="caret">
          <i
            v-show="selectedMeasure && selectedMeasure.id"
            id="delete-icon"
            class="fal fa-times"
          />
          <div v-show="selectedMeasure && !selectedMeasure.id" />
        </template>
        <template slot="noResult">
          {{ $t('noResultsFound') }}
        </template>
      </multiselect>
      <input
        v-model="hasMeasureSelected"
        v-validate="measureValidations"
        type="hidden"
        name="measures"
        :data-vv-as="$t('measure')"
      >
      <span
        v-show="errors.has('measures')"
        class="error"
      >{{ errors.first('measures') }}</span>
    </div>
    <template v-if="selectedMeasure && !selectedMeasure.clinical_administered">
      <div
        v-if="showScheduleType"
        class="row"
      >
        <div class="col-5">
          <div class="form-group">
            <label for="schedule_type">{{ $t('schedule_type') }} *</label>
            <multiselect
              v-if="scheduleTypeOptions"
              v-model="selectedScheduleType"
              :options="scheduleTypeOptions"
              open-direction="bottom"
              :searchable="false"
              :show-labels="false"
              :clear-on-select="true"
              :preserve-search="currentBrowser.isExplorer"
              :hide-selected="true"
              :placeholder="$t('select')"
              label="text"
              @select="assignTypeToSchedule"
            />
            <input
              v-model="schedule.schedule_type"
              v-validate="'required'"
              type="hidden"
              name="schedule_type"
              :data-vv-as="$t('schedule_type')"
            >
            <span
              v-show="errors.has('schedule_type')"
              class="error"
            >{{ errors.first('schedule_type') }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="showFrequency"
        class="row"
      >
        <div class="col-5">
          <div class="form-group">
            <label for="frequency">{{ $t('frequency') }} *</label>
            <multiselect
              v-if="frequencyOptions"
              v-model="selectedFrequency"
              :options="frequencyOptions"
              open-direction="bottom"
              :searchable="false"
              :show-labels="false"
              :clear-on-select="true"
              :preserve-search="currentBrowser.isExplorer"
              :hide-selected="true"
              :placeholder="$t('select')"
              label="text"
              @select="assignFrequencyToSchedule"
            />
            <input
              v-model="schedule.frequency"
              v-validate="'required'"
              type="hidden"
              name="frequency"
              :data-vv-as="$t('frequency')"
            >
            <span
              v-show="errors.has('frequency')"
              class="error"
            >{{ errors.first('frequency') }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="showCalendar && !showDays"
        class="row"
      >
        <div class="col-5">
          <div class="form-group">
            <label>{{ $t('firstDueOn') }} *</label>
            <datepicker
              v-model="schedule.due_date"
              class="form-control"
              format="MMMM dd, yyyy"
              :open-date="initialDate"
              :disabled-dates="disabledDates"
              :highlighted="highlightedDates"
              :placeholder="$t('selectDate')"
              @changedMonth="changedMonth"
              @opened="initPopoverAndDate"
              @selected="$validator.validate('nextDueDate')"
            />
            <input
              v-model="schedule.due_date"
              v-validate="'required'"
              type="hidden"
              name="nextDueDate"
              :data-vv-as="$t('nextDueDate')"
            >
            <span
              v-show="errors.has('nextDueDate')"
              class="error"
            >{{ errors.first('nextDueDate') }}</span>
            <div
              v-if="tooltip.show"
              class="tooltip vue-tooltip-theme calendar-tooltip"
              role="tooltip"
              :style="getTooltipStyles"
            >
              <div
                class="tooltip-inner"
                v-html="getTooltipContent()"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="schedule.measure_id && showDays && !dailyFrequencySelected"
        class="row"
      >
        <div class="col-5">
          <div class="form-group">
            <label>{{ $t('day') }}</label>
            <select
              v-model="schedule.day_of_week"
              v-validate="dayValidationInstruction"
              name="day"
              class="form-control"
              :data-vv-as="$t('day')"
            >
              <option value="" />
              <option
                v-for="day in daysOfWeek"
                :key="day.key"
                :value="day.key"
              >
                {{ day.label }}
              </option>
            </select>
            <span
              v-show="errors.has('day')"
              class="error"
            >{{ errors.first('day') }}</span>
          </div>
        </div>
      </div>
    </template>
    <div
      v-else
      class="clinical-administered"
    >
      {{ $t('clinicalAdministeredMessage') }}
    </div>
  </form>
</template>

<script>
import DaysOfWeek from '@/data/days-of-week-data'
import ScheduleTypes from '@/data/schedule-types'
import { CalendarHelper } from '@/mixins/CalendarHelper'
import { PatientHelper } from '@/mixins/PatientHelper'
import { SchedulingType } from '@/mixins/scheduling/SchedulingType'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import Datepicker from 'vuejs-datepicker'
import { mapGetters } from 'vuex'

const initialData = function () {
  return {
    availableMeasuresList: this && this.availableMeasures ? this.availableMeasures : [],
    selectedMeasure: {},
    highlightedDates: [],
    calendarItemsByDate: {},
    schedule: {},
    hasMeasureSelected: null,
    initialDate: null,
    daysOfWeek: DaysOfWeek,
    originalDaysOfWeek: DaysOfWeek,
    selectedFrequency: {},
    selectedDay: '',
    disabledDates: {
      to: moment().startOf('day').toDate()
    },
    scheduleTypeOptions: ScheduleTypes,
    selectedScheduleType: null,
    showCalendar: false,
    showFrequency: false,
    showScheduleType: false
  }
}

export default {
  name: 'ScheduleMeasureForm',
  components: {
    Multiselect,
    Datepicker
  },
  mixins: [PatientHelper, CalendarHelper, SchedulingType],
  props: ['customMeasure', 'availableMeasures', 'isDisabled', 'frequencyOptions', 'showDays', 'measuresAssigned', 'measureListTitle', 'showMeasuresAssigned', 'appointments', 'showVisitType', 'context'],
  data: initialData,
  computed: {
    ...mapGetters({
      currentBrowser: 'getCurrentBrowser',
      gSettings: 'generalSettings'
    }),
    dailyFrequencySelected () {
      return this.selectedFrequency && this.selectedFrequency.value === 2
    },
    measureValidations () {
      return this.$route.name === 'ClinicianAddProgramToPatient' ? '' : 'required'
    },
    dayValidationInstruction () {
      const v = this.selectedFrequency ? this.selectedFrequency.value : null
      const visitSchedulingSelected = this.selectedScheduleType && this.selectedScheduleType.value === 'visit'
      return (visitSchedulingSelected || v === 2) ? '' : 'required'
    }
  },
  watch: {
    availableMeasures (newVal, oldVal) {
      this.availableMeasuresList = this.availableMeasures
    },
    selectedMeasure: {
      handler (n, o) {
        if (o && n) {
          const isDirty = Object.keys(this.selectedMeasure).some(field => {
            const oldValue = field === 'id' ? o[field] : o[field]
            const newValue = field === 'id' ? n[field] : n[field]
            return newValue !== oldValue
          })
          this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', isDirty)
          this.highlightedDates = this.getHighlightedDates()
          this.calcCalendarItemsByDate()
          if (!this.showVisitType) {
            this.showFrequency = true
          }
        }
      },
      deep: true
    },
    selectedFrequency () {
      this.highlightedDates = this.getHighlightedDates()
      this.calcCalendarItemsByDate()
      this.updateDaysOfWeek()
    },
    selectedScheduleType (n) {
      if (!this.gSettings.ehr_integration_available) {
        this.showCalendar = true
        this.showFrequency = true
        return
      }
      this.showCalendar = (n)
      this.showFrequency = (n) && n.value === 'calendar'

      if (n && n.value === 'visit') {
        this.frequencyOptions.forEach(frequency => {
          if (frequency.value === 1) {
            this.selectedFrequency = frequency
            this.assignFrequencyToSchedule(frequency)
          }
        })
      }

      this.highlightedDates = this.getHighlightedDates()
      this.calcCalendarItemsByDate()
      this.updateDaysOfWeek()
    }
  },
  mounted () {
    const el = document.getElementById('delete-icon')
    if (el) {
      el.addEventListener('click', () => {
        this.resetForm()
        this.availableMeasuresList = this.availableMeasures ? this.availableMeasures : []
        this.$emit('assignMeasureToSchedule', null)
      })
    }
  },
  destroyed () {
    document.body.removeEventListener('mouseover', this.showTooltip, false)
    this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', false)
  },
  methods: {
    initPopoverAndDate () {
      document.body.addEventListener('mouseover', this.showTooltip, false)
      if (!this.schedule.due_date) {
        this.schedule.due_date = new Date()
        this.initialDate = new Date()
      }
    },
    searchMeasures (query) {
      const q = query.toLowerCase()
      this.availableMeasuresList = this.availableMeasures.filter(obj => {
        return obj.text.toLowerCase().indexOf(q) > -1 || obj.conditions.toLowerCase().indexOf(q) > -1
      })
    },
    scheduleMeasure (params) {
      return new Promise((resolve) => {
        if (!this.selectedMeasure.id && params && params.addAnother === false && this.$route.name !== 'ClinicianPatientAddMeasure') {
          this.$emit('finish')
          resolve(true)
          return true
        }

        if (!this.selectedMeasure.id && this.$route.name !== 'ClinicianPatientAddMeasure') {
          resolve(true)
          return true
        }

        this.$validator.validateAll().then((valid) => {
          if (!valid) { return resolve(false) }
          const schedule = {
            scheduleData: this.schedule,
            addAnother: params && params.addAnother,
            customizable: this.selectedMeasure.customizable
          }
          if (this.showProgramsUserIsMember) {
            schedule.programs = [this.activePatientProgramsAssignation[0].program.id]
          }
          this.$emit('schedule', schedule)
          this.$emit('assignMeasureToSchedule', null)
          resolve(true)
        })
      })
    },
    assignMeasureToSchedule (measure) {
      this.schedule.measure_id = measure.value
      this.schedule.measure_name = measure.text
      this.schedule.abbreviation = measure.abbreviation
      this.schedule.clinical_administered = measure.clinical_administered
      this.hasMeasureSelected = 1
      this.selectFrequency(measure.default_frequency_reference.value)
      const assigned = this.measuresAssigned.find(measure => measure.measure_id === this.schedule.measure_id && !measure.completed)
      if (assigned && this.context === 'patient') {
        this.$toast.warning({ message: this.$t('measureAlreadyScheduled', { date: moment(assigned.due_date).format('MMMM DD, YYYY') }) })
      }
      this.$emit('assignMeasureToSchedule', this.schedule)
      if (!this.gSettings.ehr_integration_available) {
        this.showCalendar = true
        this.showFrequency = true
        return
      }
      this.showScheduleType = this.showVisitType
    },
    assignTypeToSchedule (type) {
      this.schedule.schedule_type = type.value
    },
    assignFrequencyToSchedule (frequency) {
      this.schedule.frequency = frequency.value
      this.updateDaysOfWeek()
    },
    selectFrequency (value) {
      this.frequencyOptions.forEach(frequency => {
        if (frequency.value === value) {
          this.selectedFrequency = frequency
          this.assignFrequencyToSchedule(frequency)
        }
      })
    },
    resetForm () {
      const s = initialData()
      Object.keys(s).forEach(key => {
        if (key !== 'availableMeasuresList') {
          this[key] = s[key]
        }
      })
      this.$validator.reset('measures')
    },
    updateDaysOfWeek () {
      this.daysOfWeek = this.getDaysOfWeek()
    },
    getDaysOfWeek () {
      const daysOfWeek = this.originalDaysOfWeek.slice()
      if (this.selectedFrequency && (this.selectedFrequency.type_id === 0 || this.selectedFrequency.type_id === 2)) {
        daysOfWeek.unshift({ abbr: 'Discharge', key: 101, label: 'Discharge' })
      }
      daysOfWeek.unshift({ abbr: 'Admission', key: 100, label: 'Admission' })
      return daysOfWeek
    }
  }
}
</script>
<style lang="scss" scoped>
.measure-selector {
  .fal{
    display: none;
  }
  &:hover {
  .fal{
    display: flex;
  }
  }
}
.program-edit {
  .patient-add-measure {
    .measures-assigned {
      label {
        font-weight: normal !important;
      }
    }
  }
}
.as-placeholder {
  font-size: 18px;
  font-weight: 300;
}
</style>
