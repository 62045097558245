<template>
  <div class="patient-add-measure">
    <div class="patient-add-measure-info">
      <div>
        <div v-if="!compactView">
          <h3>{{ $t('addMeasure') }}</h3>
          <p class="title">
            {{ title }}
          </p>
          <div
            v-if="showPrograms"
            class="user-programs"
          >
            <p class="user-programs-title">
              {{ $t('program') }}
            </p>
            <p
              v-for="(assignatinon, index) in activePatientProgramsAssignation"
              :key="index"
              class="user-programs-name"
            >
              {{ assignatinon.program.full_name }}
            </p>
          </div>
          <div
            v-else-if="showProgramSelector"
            class="program-selector"
          >
            <label for="program">{{ $t('program') }}</label>
            <multiselect
              id="program"
              v-model="selectedProgramAssignation"
              :options="activePatientProgramsAssignation"
              :custom-label="customLabel"
              :show-labels="false"
              :hide-selected="false"
              :allow-empty="false"
              track-by="id"
              name="program"
            >
              <template slot="noResult">
                {{ $t('noResultsFound') }}
              </template>
            </multiselect>
          </div>
          <div
            v-if="respondentName"
            class="respondent-name"
          >
            <span v-html="respondentName" />
          </div>
        </div>

        <div
          v-if="!loading && showOldScheduleForm && showMeasuresAssigned"
          class="measures-assigned"
        >
          <label for="availableMeasures">{{ measureListTitle }}</label>
          <measure-assigned
            v-for="(schedule, index) in filteredMeasuresAssigned"
            :key="index"
            context="patient"
            :measure-name="schedule.measure_name"
            :client-measure-id="schedule.id"
            :in-use="schedule.in_use"
            :hide-buttons="false"
            @deleteDialogState="setDeleteDialogState"
            @onDelete="$emit('delete')"
          />

          <div
            v-for="(schedule, index) in measuresEnqueued"
            :key="index"
            class="measure"
          >
            {{ schedule.measure_name }}
            <span
              v-if="!isDisabled"
              class="delete"
              @click="softDelete(schedule)"
            >{{ $t('delete') }}</span>
          </div>
        </div>
        <div v-else-if="!loading && showMeasuresAssigned">
          <div class="tw-relative tw-overflow-x-auto">
            <table class="tw-w-full tw-text-left tw-text-sm tw-font-normal tw-mb-6">
              <thead class="tw-uppercase tw-text-xs">
                <tr>
                  <th
                    scope="col"
                    class="tw-px-3 tw-py-3 tw-font-normal tw-cursor-pointer"
                    :class="{ 'tw-font-bold': sortColumn === 'measure_name' }"
                    @click="setSortOrder('measure_name')"
                  >
                    {{ $t('measure') }}
                    <span
                      class="tbl-icon"
                      :class="{ asc: (sortOrder === 'asc' && sortColumn === 'measure_name'), desc: (sortOrder === 'desc' && sortColumn === 'measure_name') }"
                    />
                  </th>
                  <th
                    scope="col"
                    class="tw-px-6 tw-py-3 tw-font-normal tw-cursor-pointer"
                    :class="{ 'tw-font-bold': sortColumn === 'frequency' }"
                    @click="setSortOrder('frequency')"
                  >
                    {{ $t('frequency') }}
                    <span
                      class="tbl-icon"
                      :class="{ asc: (sortOrder === 'asc' && sortColumn === 'frequency'), desc: (sortOrder === 'desc' && sortColumn === 'frequency') }"
                    />
                  </th>
                  <th
                    scope="col"
                    class="tw-px-6 tw-py-3 tw-font-normal tw-cursor-pointer"
                    :class="{ 'tw-font-bold': sortColumn === 'day_of_week' }"
                    @click="setSortOrder('day_of_week')"
                  >
                    {{ $t('day') }}
                    <span
                      class="tbl-icon"
                      :class="{ asc: (sortOrder === 'asc' && sortColumn === 'day_of_week'), desc: (sortOrder === 'desc' && sortColumn === 'day_of_week') }"
                    />
                  </th>
                  <th
                    scope="col"
                    class="tw-px-6 tw-py-3 tw-font-normal"
                  />
                </tr>
              </thead>
              <tbody>
                <measure-assigned
                  v-for="(schedule, index) in filteredMeasuresAssigned"
                  :key="index"
                  ref="measureTable"
                  context="program"
                  :abbreviation="schedule.abbreviation"
                  :measure-name="schedule.measure_name"
                  :day-of-week-key="schedule.day_of_week"
                  :frequency-key="schedule.frequency"
                  :frequency-options="frequencyOptions"
                  :client-measure-id="schedule.id"
                  :in-use="schedule.in_use"
                  :hide-buttons="false"
                  @deleteDialogState="setDeleteDialogState"
                  @onDelete="$emit('delete')"
                />
              </tbody>
            </table>
          </div>
        </div>

        <loading-spinner
          v-else-if="loading"
          :no-text="true"
          class="tw-text-center tw-m-5"
        />

        <schedule-measure-form
          v-show="!isDisabled"
          ref="scheduleMeasureForm"
          :context="context"
          :is-disabled="isDisabled"
          :custom-measure="customMeasure"
          :show-visit-type="showVisitType"
          :measures-assigned="measuresAssigned"
          :measure-list-title="measureListTitle"
          :appointments="appointments"
          :show-measures-assigned="showMeasuresAssigned"
          :available-measures="availableMeasures"
          :frequency-options="frequencyOptions"
          :show-days="showDays"
          @schedule="emitSchedule"
          @assignMeasureToSchedule="assignMeasureToSchedule"
          @finish="$emit('finish')"
        />
      </div>
    </div>
    <div
      v-if="!compactView"
      class="buttons"
    >
      <button
        v-if="!showAddAnother"
        type="button"
        :disabled="disableActionButtons"
        class="btn btn-outline-secondary"
        @click="resetForm"
      >
        {{ $t('cancel') }}
      </button>
      <button
        v-if="showAddAnother && measuresAssignedToSchedule"
        type="button"
        :disabled="disableActionButtons"
        class="btn btn-outline-secondary"
        @click="scheduleMeasure({addAnother: true})"
      >
        {{ $t('addAnotherMeasure') }}
      </button>
      <button
        type="button"
        :disabled="disableActionButtons"
        class="btn btn-secondary"
        @click="scheduleMeasure({addAnother: false})"
      >
        {{ saveButtonLabel }}
      </button>
    </div>
    <div v-if="showAddAnotherMeasureCompact">
      <button
        type="button"
        class="btn btn-outline-secondary"
        @click="scheduleMeasure"
      >
        + Add another measure
      </button>
    </div>
    <div
      v-if="customMeasure"
      class="patient-add-measure-customize"
    >
      <clinician-customize-measure
        :measure-id="customMeasure"
        @finish="$emit('finish')"
      />
    </div>
    <delete-dialog
      v-if="deleteDialog.dialogState"
      key="schedule.id"
      :title="'Are you sure you want to delete this measure?'"
      @onDelete="onDelete(deleteDialog.measureId)"
      @onCancel="onCancel"
    />
  </div>
</template>

<script>
import ClinicianCustomizeMeasure from '@/components/clinician/measures/ClinicianCustomizeMeasure'
import MeasureAssigned from '@/components/clinician/patients/MeasureAssigned'
import DeleteDialog from '@/components/common/DeleteDialog'
import LoadingSpinner from '@/components/common/LoadingSpinner'
import ScheduleMeasureForm from '@/components/common/schedule/ScheduleMeasureForm'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { Helpers } from '@/mixins/Helpers'
import { PatientHelper } from '@/mixins/PatientHelper'
import { DELETE_SCHEDULED_MEASURE } from '@/store/modules/clinicians/constants.js'
import { DELETE_PROGRAM_MEASURE } from '@/store/modules/programs/constants.js'
import orderBy from 'lodash/orderBy'
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'

export default {
  name: 'ScheduleMeasure',
  components: {
    ClinicianCustomizeMeasure,
    ScheduleMeasureForm,
    MeasureAssigned,
    Multiselect,
    DeleteDialog,
    LoadingSpinner
  },
  mixins: [Helpers, PatientHelper, DateTimeHelper],
  props: {
    /* eslint-disable vue/require-prop-types */
    appointments: { default: () => { return [] } },
    availableMeasures: { default: () => { return [] } },
    context: {
      type: String,
      default: ''
    },
    frequencyOptions: { default: () => { return [] } },
    measuresAssigned: { default: () => { return [] } },
    measuresEnqueued: { default: () => { return [] } },
    showDays: { default: false },
    showMeasuresAssigned: { default: false },
    compactView: { default: false },
    title: { default: '' },
    measureListTitle: { default: function () { return this.$t('measure') } },
    saveBtnText: { default: null },
    showAddAnother: {
      type: Boolean,
      default: false
    },
    respondentName: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    showVisitType: {
      type: Boolean,
      default: false
    }
    /* eslint-enable vue/require-prop-types */
  },
  data () {
    return {
      deleteDialog: { dialogState: false, measureId: null },
      selectedProgramAssignation: null,
      measuresAssignedToSchedule: null,
      submitState: false,
      sortOrder: '',
      sortColumn: ''
    }
  },
  computed: {
    ...mapGetters({
      comingFromProgram: 'getComingFromProgram',
      lastSessionDetail: 'getLastSessionDetail',
      customMeasure: 'getCustomMeasure'
    }),
    disableActionButtons () {
      if (this.submitState) {
        return true
      }
      return false
    },
    showProgramSelector () {
      return this.activePatientProgramsAssignation.length > 1 && this.settings.programs_enabled && (this.$route.name === 'ClinicianPatientAddMeasure' || this.$route.name === 'ClinicianPatientsByProgramAddMeasureToPatient' || this.$route.name === 'ClinicianHomePageAddMeasure')
    },
    showPrograms () {
      return this.showProgramsUserIsMember && (this.$route.name === 'ClinicianPatientAddMeasure' || this.$route.name === 'ClinicianPatientsByProgramAddMeasureToPatient' || this.$route.name === 'ClinicianHomePageAddMeasure' || this.$route.name === 'ClinicianHomePageAddMeasure')
    },
    showAddAnotherMeasureCompact () {
      const someSelected = this.measuresEnqueued.length || this.measuresAssigned.length || (this.$refs && this.$refs.scheduleMeasureForm && this.$refs.scheduleMeasureForm.selectedMeasure.id)
      if (this.compactView && !this.isDisabled && someSelected && this.measuresAssignedToSchedule) {
        return true
      }
      return false
    },
    saveButtonLabel () {
      return (this.saveBtnText) ? this.saveBtnText : this.$t('confirm')
    },
    filteredMeasuresAssigned () {
      // new map to add leading zeroes to numeric values for proper sorting
      if (this.showOldScheduleForm) {
        const ids = []
        return this.measuresAssigned.filter(m => !ids.includes(m.measure_id) ? ids.push(m.measure_id) : false)
      } else {
        const appendedMeasures = this.measuresAssigned.map((m) => {
          return {
            ...m,
            abbreviation: Object.hasOwn(m, 'measure_storage') ? m.measure_storage.abbreviation : m.measure_abbreviaton,
            day_of_week: this.zeroPad(m.day_of_week, 3),
            frequency: this.zeroPad(m.frequency, 3)
          }
        })
        return orderBy(appendedMeasures, this.sortColumn, this.sortOrder)
      }
    },
    showOldScheduleForm () {
      return this.context === 'patient' || this.$route.path.includes('assignment') || this.$route.name === 'ClinicianAddProgramToPatient'
    }
  },
  created () {
    if ((this.lastSessionDetail && this.lastSessionDetail.id) || this.comingFromProgram) {
      const programId = this.comingFromProgram ? parseInt(this.comingFromProgram) : parseInt(this.lastSessionDetail.programId)
      this.selectedProgramAssignation = this.activePatientProgramsAssignation.find(pa => parseInt(pa.program_id) === programId)
      this.$store.commit('user/SET_COMING_FROM_PROGRAM', null)
    }
  },
  methods: {
    emitSchedule (schedule) {
      if (this.showProgramSelector && this.selectedProgramAssignation) {
        schedule.programs = [this.selectedProgramAssignation.program_id]
      }
      this.$emit('schedule', schedule)
    },
    assignMeasureToSchedule (schedule) {
      if (schedule && this.measuresEnqueued) {
        const alreadyEnqueued = this.measuresEnqueued.find(m => m.id === schedule.measure_id)
        if (alreadyEnqueued) {
          this.$toast.warning({ message: this.$t('measureAlreadyScheduled', { date: this.$toLocal(alreadyEnqueued.due_date, 'MMMM DD, YYYY') }) })
        }
      }
      this.measuresAssignedToSchedule = schedule
    },
    customLabel (assignation) {
      return assignation.program.name
    },
    scheduleMeasure (params) {
      return new Promise((resolve) => {
        this.submitState = true
        this.$refs.scheduleMeasureForm.scheduleMeasure(params).then(() => {
          this.submitState = false
          resolve(true)
        })
      })
    },
    softDelete (measure) {
      this.$emit('soft-delete', measure)
    },
    resetForm () {
      if ((this.$route.name === 'ClinicianPatientAddMeasure' || this.$route.name === 'ClinicianPatientsByProgramAddMeasureToPatient') && this.$parent.$parent.beforeClose) {
        this.$parent.$parent.beforeClose()
        return
      }
      return this.$refs.scheduleMeasureForm.resetForm()
    },
    setDeleteDialogState (deleteDialog) {
      this.deleteDialog = deleteDialog
      this.deleteDialog.dialogState === true ? this.submitState = true : this.submitState = false
    },
    onDelete (measureId) {
      let action = DELETE_SCHEDULED_MEASURE
      let data = { clientMeasureId: measureId }
      if (['ClinicianProgramAddMeasure', 'SettingsProgramAddMeasure'].includes(this.$route.name)) {
        action = DELETE_PROGRAM_MEASURE
        data = { program_id: this.$route.params.programId, measure_id: measureId }
      }
      this.$genericDispatch(action, data).then(() => {
        this.onCancel()
        this.$emit('delete')
      })
    },
    onCancel () {
      this.deleteDialog = { dialogState: false, measureId: null }
      this.setDeleteDialogState(this.deleteDialog)
    },
    setSortOrder (column) {
      if (column === this.sortColumn) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortOrder = 'asc'
      }
      this.sortColumn = column
    },
    zeroPad (num, places) {
      // return num.toString().padStart(places, '0')
      // console.log('num num ', num)
      return String(num).padStart(places, '0')
    }
  }
}
</script>
<style lang="scss" scoped>
  .program-edit .patient-add-measure .measures-assigned label {
    font-weight: normal !important;
  }
  .user-programs {
    padding-left: 15px;
    color: #0a3560;
    &-title {
      font-family: MuseoSans;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.5;
      letter-spacing: normal;
      text-transform: uppercase;
      margin-bottom: 0px;
    }
    &-name {
      font-size: 18px;
      font-weight: 300;
      text-transform: capitalize;
    }
  }
  .respondent-name {
    color: #0a3560;
    padding: 15px 0 10px 15px;
    font-family: MuseoSans;
    font-size: 18px;
    font-weight: 300;
    text-transform: capitalize;
  }

  .tbl-icon {
    font-family: "Font Awesome 5 Pro"
  }
  .tbl-icon.asc, .tbl-icon.desc {
    font-weight: 700;
  }
  .tbl-icon.asc:before {
    content: "\f176";
    padding-left: 2px;
  }

  .tbl-icon.desc:before {
    content: "\f175";
    padding-left: 2px;
  }
</style>
