<template>
  <div
    v-if="showOldScheduleForm"
    class="measure"
  >
    {{ measureName }}
    <span
      v-if="!inUse && !hideButtons"
      class="delete"
      @click="setDialogState"
    >Delete</span>
    <span
      v-if="inUse && !hideButtons"
      class="in-use"
    >In Use</span>
    <delete-dialog
      v-if="deleteDialog"
      key="schedule.id"
      :title="'Are you sure you want to delete this measure?'"
      @onDelete="onDelete"
      @onCancel="onCancel"
    />
  </div>
  <tr
    v-else
    class="tw-border-b last:tw-border-b-0"
  >
    <td
      v-tooltip="tooltipData"
      scope="row"
      class="tw-px-3 tw-py-3 tw-max-w-48 tw-truncate"
    >
      {{ abbreviation }}
    </td>
    <td
      scope="row"
      class="tw-px-6 tw-py-3 tw-whitespace-nowrap"
    >
      {{ frequency }}
    </td>
    <td
      scope="row"
      class="tw-px-6 tw-py-3 tw-whitespace-nowrap"
    >
      {{ dayOfWeek }}
    </td>
    <td
      v-if="!inUse && !hideButtons"
      scope="row"
      class="tw-px-4 tw-py-3 tw-text-right"
    >
      <i
        class="fal fa-times tw-font-light tw-whitespace-nowrap tw-cursor-pointer"
        @click="setDialogState"
      />
    </td>
    <span
      v-if="inUse && !hideButtons"
      class="in-use"
    >In Use</span>
  </tr>
</template>

<script>
import DeleteDialog from '@/components/common/DeleteDialog'
import DaysOfWeek from '@/data/days-of-week-data'
import { Helpers } from '@/mixins/Helpers'
import { DELETE_SCHEDULED_MEASURE } from '@/store/modules/clinicians/constants.js'
import { DELETE_PROGRAM_MEASURE } from '@/store/modules/programs/constants.js'
export default {
  name: 'MeasureAssigned',
  components: {
    DeleteDialog
  },
  mixins: [Helpers],
  props: [
    'abbreviation',
    'clientMeasureId',
    'dayOfWeekKey',
    'frequencyKey',
    'frequencyOptions',
    'hideButtons',
    'inUse',
    'measureName',
    'context'
  ],
  data () {
    return {
      deleteDialog: false,
      daysOfWeek: DaysOfWeek
    }
  },
  computed: {
    frequency () {
      if (this.context === 'program') {
        const match = this.frequencyOptions.filter(i => Number(i.value) === Number(this.frequencyKey))
        return match.length === 1 ? match[0].text : ''
      }
      return null
    },
    dayOfWeek () {
      if (this.context === 'program') {
        const match = this.daysOfWeek.filter(i => Number(i.key) === Number(this.dayOfWeekKey))
        if (Number(this.dayOfWeekKey) === 100) {
          return 'Admission'
        }
        if (Number(this.dayOfWeekKey) === 101) {
          return 'Discharge'
        }
        return match.length === 1 ? match[0].label : ''
      }
      return null
    },
    tooltipData () {
      return { content: this.measureName, placement: 'top-start', classes: ['measure-name'] }
    },
    showOldScheduleForm () {
      return this.context === 'patient' || this.$route.path.includes('assignment') || this.$route.name === 'ClinicianAddProgramToPatient'
    }
  },
  methods: {
    onDelete () {
      let action = DELETE_SCHEDULED_MEASURE
      let data = { clientMeasureId: this.clientMeasureId }
      if (['ClinicianProgramAddMeasure', 'SettingsProgramAddMeasure'].includes(this.$route.name)) {
        action = DELETE_PROGRAM_MEASURE
        data = { program_id: this.$route.params.programId, measure_id: this.clientMeasureId }
      }
      this.$genericDispatch(action, data).then(() => {
        this.onCancel()
        this.$emit('onDelete')
      })
    },
    onCancel () {
      this.deleteDialog = false
      this.$emit('deleteDialogState', this.deleteDialog)
    },
    setDialogState () {
      this.$emit('deleteDialogState', { dialogState: !this.deleteDialog, measureId: this.clientMeasureId })
    }
  }
}
</script>
