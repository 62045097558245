<template>
  <div class="add-measure-to-patient">
    <ScheduleMeasure
      v-if="!loadingPatientData"
      ref="scheduleMeasure"
      context="patient"
      :show-visit-type="true"
      :title="title"
      :respondent-name="respondentName"
      :available-measures="availableMeasuresList"
      :frequency-options="schedulingOptions.frequency_options"
      :measures-assigned="schedulingOptions.measures_assigned"
      :appointments="schedulingOptions.appointments"
      :save-btn-text="saveText"
      :loading="loadingSchedulingOptions"
      @schedule="schedule"
      @finish="customizationFinished"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import { PatientHelper } from '@/mixins/PatientHelper'
import moment from 'moment'
import ScheduleMeasure from '@/components/common/schedule/ScheduleMeasure'
import { LastSessionDetail } from '@/mixins/LastSessionDetail'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'

export default {
  name: 'ClinicianAddMeasureToPatient',
  components: {
    ScheduleMeasure
  },
  mixins: [Helpers, PatientHelper, LastSessionDetail],
  data () {
    return {
      loadingSchedulingOptions: false,
      loadingPatientData: false,
      measuresAssigned: [],
      availableMeasuresList: []
    }
  },
  computed: {
    ...mapGetters({
      patientProgramsAssignation: 'getSinglePatientPrograms',
      patient: 'getSinglePatient',
      assignments: 'getAssignments',
      schedulingOptions: 'getSchedulingOptions',
      activeAssignment: 'getActiveAssignment'
    }),
    title () {
      let text = this.activeAssignment.patient_first_name
      if (this.activeAssignment.patient_last_name) {
        text += ` ${this.activeAssignment.patient_last_name}`
      }
      return text
    },
    respondentName () {
      if (this.activeAssignment.patient_relation_id !== 1) {
        return `${this.activeAssignment.first_name} ${this.activeAssignment.last_name} <small> (${this.activeAssignment.patient_relation}) </small>`
      }
      return ''
    },
    saveText () {
      return this.$t('confirm')
    }
  },
  created () {
    if (this.activeAssignment.patient_status === 'inactive') {
      this.$toast.warning({ message: this.$t('willActivatePatient', { action: this.$t('addingMeasure') }) })
    }
    this.loadingPatientData = true
    this.getPatient(this.getPatientId()).then(() => {
      if (['ClinicianPatientsByProgramAddMeasureToPatient', 'ClinicianAddMeasureToPatient', 'ClinicianHomePageAddMeasure'].includes(this.$route.name)) {
        const getPrograms = this.programsView && this.programsEnabled && ['ClinicianPatientsByProgramAddMeasureToPatient', 'ClinicianHomePageAddMeasure'].includes(this.$route.name)
        this.getDataAssignment(getPrograms)
      } else {
        this.loadingPatientData = false
      }
      this.fetchSchedulingOptions()
    })
  },
  destroyed () {
    this.$store.dispatch('RESET_SCHEDULING_OPTIONS')
  },
  methods: {
    getAssignmentId () {
      return (this.$route.params.ccauId) ? this.$getDecodedId(this.$route.params.ccauId) : (this.$route.params.patientId) ? this.patient.client_clinic_assignment_user_id : this.activeAssignment.ccauId
    },
    fetchSchedulingOptions () {
      const assignmentId = this.getAssignmentId()
      if (assignmentId) {
        const loader = this.$loading.show()
        this.loadingSchedulingOptions = true

        this.$store.dispatch('GET_SCHEDULING_OPTIONS', { ccauId: assignmentId }).then(() => {
          this.measuresAssigned = this.schedulingOptions.measures_assigned
          this.availableMeasuresList = this.schedulingOptions.available_measures
        }).catch((e) => {
          this.$handleApiError(e)
        }).finally(() => {
          loader.hide()
          this.loadingSchedulingOptions = false
        })
      }
    },
    schedule (options) {
      const schedule = options.scheduleData
      this.validateIsnotAlreadyAssigned(schedule).then(() => {
        const loader = this.$loading.show()
        schedule.client_clinic_assignment_user_id = this.getAssignmentId()
        schedule.programIds = options.programs
        schedule.isAddMeasure = true
        this.$reactivateIfInactive(this.activeAssignment).then(() => {
          this.$store.dispatch('SCHEDULE_MEASURE', schedule).then((response) => {
            this.$refreshActiveAssignmentData()
            this.handleScheduleToast(response)
            componentRefreshSingleton.refreshComponentViewByName('ClinicianPatientHeader')

            if (options.customizable) {
              this.$store.dispatch('SET_CUSTOM_MEASURE', response.id)
              this.addAnother = options && options.addAnother
              return
            }
            if (options && options.addAnother) {
              this.$refs.scheduleMeasure.resetForm()
              this.fetchSchedulingOptions()
              return
            }

            if (this.lastSessionDetail) {
              this.fetchTeamLastSession({ ...this.lastSessionDetail })
            }

            if (this.$route.name === 'ClinicianPatientsByProgramAddMeasureToPatient') {
              this.$router.push({ name: 'ClinicianPatientsByProgramAddMeasureToPatientOverview', params: { patientId: this.$route.params.patientId, ccauId: this.$route.params.ccauId, ccaId: this.$route.params.ccaId } })
              return
            }

            const { ccaId, ccauId } = this.getParamsForAssignments()
            const url = this.$route.name === 'ClinicianHomePageAddMeasure' ? 'ClinicianHomePageMeasuresAddedOverview' : 'ClinicianPatientMeasuresAddedOverview'
            this.$router.push({
              name: url,
              params: {
                ccauId: this.$getEncodedId(ccauId),
                ccaId: this.$getEncodedId(ccaId)
              }
            })
          }).catch((e) => {
            this.$handleApiError(e)
          }).finally(() => {
            loader.hide()
          })
        })
      })
    },
    handleScheduleToast (response) {
      const notificationsEnabled = this.patient.email_notification || this.patient.mobile_notification
      const extra = this.getNotificationType()

      if (this.$route.query.justAdded && notificationsEnabled) {
        this.$toast.success({ message: this.$t('measureScheduledInvitationSent', { extra: extra }) })
        return true
      }

      if (response.invitation_sent) {
        this.$toast.success({ message: this.$t('measureScheduledInvitationSent', { extra: extra }) })
        return true
      }

      this.$toast.success({ message: this.$t('measureAdded') })
      if (!response.invitation_sent && parseInt(response.reminder_sent) === 1) {
        this.$toast.success({ message: this.$t('reminderSent') })
      }
    },
    getNotificationType () {
      if (this.patient.email_notification && this.patient.mobile_notification) {
        return this.$t('invitationTextWhenToEmailAndSms')
      }
      if (this.patient.email_notification) {
        return this.$t('invitationTextWhenToEmail')
      }
      if (this.patient.mobile_notification) {
        return this.$t('invitationTextWhenToSms')
      }
    },
    assignFrequencyToSchedule (frequency) {
      this.schedule.frequency = frequency.value
    },
    selectFrecuency (value) {
      this.schedulingOptions.frequency_options.forEach(frequency => {
        if (frequency.value === value) {
          this.selectedFrequency = frequency
          this.assignFrequencyToSchedule(frequency)
        }
      })
    },
    scheduleMeasure (params) {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.validateIsnotAlreadyAssigned().then(() => {
            const loader = this.$loading.show()
            const scheduleData = Object.assign({}, this.schedule)
            scheduleData.client_clinic_assignment_user_id = this.getAssignmentId()
            this.$store.dispatch('SCHEDULE_MEASURE', scheduleData)
              .then((response) => {
                this.$toast.success({ message: this.$t('measureScheduled') })

                if (params && params.addAnother) {
                  this.resetState()
                  this.fetchSchedulingOptions()
                  return
                }

                const selectedMeasure = { ...this.selectedMeasure }
                this.selectedMeasure = {}
                if (selectedMeasure.customizable) {
                  this.$store.dispatch('SET_CUSTOM_MEASURE', response.id)
                  this.addAnother = params && params.addAnother
                  return
                }
                if (params && params.addAnother) {
                  this.resetState()
                  this.fetchSchedulingOptions()
                  return
                }

                this.redirectBasedOnRoute()
              })
              .catch((e) => {
                this.$handleApiError(e)
              })
              .finally(() => {
                loader.hide()
              })
          })
        }
      })
    },
    redirectBasedOnRoute () {
      let redirectRoute
      if (this.$route.name === 'ClinicianPatientAddMeasure') {
        const ccauId = this.$route.params.ccauId
        const ccaId = this.$route.params.ccaId
        redirectRoute = { name: 'ClinicianPatientMeasures', params: { ccauId: ccauId, ccaId: ccaId } }
      } else {
        redirectRoute = { name: 'ClinicianPatientsList' }
      }
      this.$store.dispatch('GET_PATIENTS')
      this.$router.push(redirectRoute)
    },
    validateIsnotAlreadyAssigned (schedule) {
      return new Promise((resolve) => {
        const found = this.schedulingOptions.measures_assigned.find((measureAssigned) => measureAssigned.measure_id === schedule.measure_id && measureAssigned.completed !== 1 && moment(measureAssigned.due_date).format('YYYY-MM-DD') === moment(schedule.due_date).format('YYYY-MM-DD'))
        if (found) {
          const promptOptions = {
            title: this.$t('warning'),
            message: this.$t('measureAlreadyScheduledWithConfirmation', { date: moment(found.due_date).format('MMMM DD, YYYY') })
          }
          this.$promptBeforeAction(promptOptions, () => resolve(), () => {
            this.$refs.scheduleMeasure.resetForm()
            this.fetchSchedulingOptions()
          })
        } else {
          resolve(true)
        }
      })
    },
    getParamsForAssignments () {
      const strategy = this.getParamsForAssignmentsStrategy()
      return strategy()
    },
    getParamsForAssignmentsStrategyOne () {
      return {
        ccaId: this.$getDecodedId(this.$route.params.ccaId),
        ccauId: parseInt(this.$getDecodedId(this.$route.params.ccauId))
      }
    },
    getParamsForAssignmentsStrategyTwo () {
      return {
        ccaId: this.patient.assignment_id,
        ccauId: this.patient.client_clinic_assignment_user_id
      }
    },
    getParamsForAssignmentsStrategy () {
      const currentRoute = this.$route.name
      let strategy = this.getParamsForAssignmentsStrategyOne
      switch (currentRoute) {
        case 'ClinicianPatientsByProgramAddMeasureToPatient':
          strategy = this.getParamsForAssignmentsStrategyOne
          break
        case 'ClinicianHomePageAddMeasure':
          strategy = this.getParamsForAssignmentsStrategyOne
          break
        case 'ClinicianPatientAddMeasure':
          strategy = this.getParamsForAssignmentsStrategyOne
          break
        case 'ClinicianAddMeasureToPatient':
          strategy = this.getParamsForAssignmentsStrategyTwo
          break
      }
      return strategy
    },
    getDataAssignment (getPrograms = false) {
      const { ccaId, ccauId } = this.getParamsForAssignments()
      if (ccaId && ccauId) {
        const loader = this.$loading.show()
        this.$store.dispatch('GET_ASSIGNMENTS', ccaId).then(() => {
          this.$store.dispatch('SET_ASSIGNMENT_VIEW', parseInt(ccauId))
          if (getPrograms) {
            this.$store.dispatch('GET_SINGLE_PATIENT_PROGRAMS', this.activeAssignment.patient_id).catch((e) => {
              this.$handleApiError(e)
            })
          }
        }).catch((e) => {
          this.$handleApiError(e)
        }).finally(() => {
          this.loadingPatientData = false
          loader.hide()
        })
      }
    },
    customizationFinished () {
      if (this.addAnother) {
        this.resetState()
        this.fetchSchedulingOptions()
      } else {
        this.redirectBasedOnRoute()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.add-measure-to-patient {
  height: 100%;
}
</style>
